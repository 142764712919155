import React, { useCallback, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "./AsyncAvFieldSelect";


const SearchableAgentDropdown = (props) => {

  const {
    placeholder = "Search",
    isRequired = false,
    name = "agent",
    isDisabled = false,
    defaultOptions = [],
    value = null,
    clientData = null,
    onChange = () => { },
    isMulti = false,
  } = props;

  const [agentOptions, setAgentOptions] = React.useState(defaultOptions);
  const { userData } = useSelector((state) => ({
    userData: state.Profile.userData,
  }));

  useEffect(() => {
    if (clientData && clientData.length > 0) {
      setAgentOptions(clientData.map((user) => ({
        value: user._id,
        label: user.firstName + " " + user.lastName + " - " + user.roleId.title
      })));
    }
  }, [clientData]);


  return (
    <AsyncAvFieldSelect 
      name={name}
      options={agentOptions}
      label={props.t("Agent")}
      errorMessage={props.t("Agent is required")}
      defaultOptions={agentOptions || defaultOptions}
      value={userData.role === "Senior BD" || 
       userData.role === "Junior BD" || 
       userData.role === "Account Manager" ? {
          label: `${userData.firstName} ${userData.lastName}`,
          value: `${userData._id}`
        } :
        value ? value :
          clientData?.agent?._id ? {
            label: `${clientData.agent.firstName} ${clientData.agent.lastName}`,
            value: `${clientData.agent._id}`
          } : ""}
      defaultValue={userData.role === "Senior BD" || 
       userData.role === "Junior BD" || 
       userData.role === "Account Manager" ? {
          label: `${userData.firstName} ${userData.lastName}`,
          value: `${userData._id}`
        } :
        value ? value :
          clientData?.agent?._id ? {
            label: `${clientData.agent.firstName} ${clientData.agent.lastName}`,
            value: `${clientData.agent._id}`
          } : ""}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
      isMulti={isMulti}
      isDisabled={isDisabled}
      disabled={isDisabled}
    />
  );
};


const mapStateToProps = (state) => ({
  agents: state.usersReducer.salesAgent,
  agentsLoading: state.usersReducer.loading,
});
export default connect(mapStateToProps, null)(withTranslation()(SearchableAgentDropdown));